module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soma Holiday","short_name":"somaholiday.com","start_url":"/","background_color":"#000000","theme_color":"#C9C9D9","display":"standalone","icon":"src/images/Augenlicht_sq.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
